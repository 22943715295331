import React, { useState } from 'react'
import LogoType from '../assets/logo-type.svg'
import LogoCoin from '../assets/logo-coin.svg'
import FormButton from '../components/FormButton'
import { useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie-player'
import animationData from '../lotties/data.json'
import { useLoginMutation } from '../service/Api'
import ILoginErrorResponse from '../interfaces/login-error-response.interface'

const Login = () => {
  const [showPasswordField, setShowPasswordField] = useState(false)
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordInputError, setPasswordInputError] = useState(false)
  const [passwordInputErrorMessage, setPasswordInputErrorMessage] = useState('')

  const [login] = useLoginMutation()

  const onNameInputChange = (value: string) => {
    setName(value)
    setShowPasswordField(true)
  }

  const onPasswordInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setPasswordInputError(false)
    setPasswordInputErrorMessage('')
    setPassword(e.currentTarget.value)
  }

  const onLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPasswordInputError(false)
    setPasswordInputErrorMessage('')

    try {
      await login({
        username: name,
        password: password
      })
        .unwrap()
        .then(() => {
          navigate('/view')
        })
    } catch (error) {
      if (isLoginErrorType(error)) {
        setPasswordInputError(true)
        setPasswordInputErrorMessage(error.data.data.error)
      }
    }
  }

  const isLoginErrorType = (obj: any): obj is ILoginErrorResponse => {
    return (
      'status' in obj &&
      'data' in obj &&
      'data' in obj.data &&
      'message' in obj.data &&
      'success' in obj.data
    )
  }
  const handleKey = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      document.getElementById('login-btn')?.click()
    }
  }

  const navigate = useNavigate()

  return (
    <div className="h-screen bg-gradient-to-b from-white to-lightBlue flex overflow-hidden">
      <div className="sm:w-[24rem] w-[90%] mx-auto flex flex-col h-full items-center py-5">
        <div className="grow h-[10%] w-auto mb-4">
          <img src={LogoType} className="h-full w-auto" alt="" />
        </div>
        <div className="grow h-[30%] w-auto mb-5">
          <img src={LogoCoin} className="h-full w-auto" alt="" />
        </div>
        <div
          className={`${
            showPasswordField ? 'w-[80%]' : 'w-full'
          } flex flex-col items-center justify-start grow h-[30%]`}
        >
          {showPasswordField ? (
            <form className="w-full" onSubmit={onLoginSubmit} id="login-form">
              <div
                className={`${
                  showPasswordField
                    ? 'visible opacity-100'
                    : 'invisible opacity-0'
                }  w-full flex flex-col items-center`}
              >
                <label
                  className="text-xl text-center mb-5"
                  htmlFor="password-input"
                >
                  Password:
                </label>
                <input
                  type="password"
                  id="password-input"
                  className="flex w-full bg-white border border-grey-600 p-2 mb-5"
                  autoComplete="on"
                  value={password}
                  onKeyDown={handleKey}
                  onChange={onPasswordInputChange}
                />
                {passwordInputError && (
                  <div className="text-red text-xs mb-5">
                    {passwordInputErrorMessage}
                  </div>
                )}
                <FormButton
                  text="SUBMIT"
                  className="bg-yellow w-1/2 text-grey-600"
                  id="login-btn"
                  type="submit"
                />
              </div>
            </form>
          ) : (
            <React.Fragment>
              <div className="text-xl text-center mb-5">
                The currency of love is built from.
              </div>
              <button
                className="bg-white rounded-lg text-grey-600 w-[70%] mx-auto py-1 shadow-lg mb-4"
                onClick={() => onNameInputChange('luna')}
              >
                <div className="text-xs tracking-tighter">
                  Login as
                  <br />
                  <span className="text-lg leading-none tracking-widest">
                    LUNA
                  </span>
                </div>
              </button>
              <button
                className="bg-white rounded-lg text-grey-600 w-[70%] mx-auto py-1 shadow-lg"
                onClick={() => onNameInputChange('ben')}
              >
                <div className="text-xs tracking-tighter">
                  Login as
                  <br />
                  <span className="text-lg leading-none tracking-widest">
                    BEN
                  </span>
                </div>
              </button>
            </React.Fragment>
          )}
        </div>
        <div className="grow h-[30%]">
          <Lottie loop animationData={animationData} play />
        </div>
      </div>
    </div>
  )
}

export default Login
