import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../service/Api'

const initialState = {
  access_token: null,
  username: null
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteToken(state) {
      state.access_token = null
      state.username = null
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        return {
          ...payload.data,
          token: payload.access_token
        }
      }
    )
  }
})

export default user.reducer
export const { deleteToken } = user.actions
