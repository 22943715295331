import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Login from './pages/Login'
import View from './pages/View'
import Give from './pages/Give'
import Take from './pages/Take'
import { useSelector } from 'react-redux'
import { RootState } from './store'
import Edit from './pages/Edit'
import { useGetMeQuery } from './service/Api'

function App() {
  const userSelector = useSelector((state: RootState) => state.user)

  const { data, isLoading } = useGetMeQuery(undefined, {})

  return (
    <BrowserRouter>
      {!isLoading && data?.id && userSelector.access_token ? (
        <Routes>
          <Route path="/" element={<Navigate replace to="/view" />} />
          <Route path="/view" element={<View />} />
          <Route path="/give" element={<Give />} />
          <Route path="/take" element={<Take />} />
          <Route path="/edit" element={<Edit />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/view" element={<Navigate replace to="/" />} />
          <Route path="/give" element={<Navigate replace to="/" />} />
          <Route path="/take" element={<Navigate replace to="/" />} />
          <Route path="/edit" element={<Navigate replace to="/" />} />
        </Routes>
      )}
    </BrowserRouter>
  )
}

export default App
