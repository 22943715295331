import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'
import IViewResponse from '../interfaces/view-response.interface'
import ITransferResponse from '../interfaces/transfer-response.interface'
import ITransferRequest from '../interfaces/transfer-request.interface'
import { deleteToken } from '../store/user/user'
import IEditResponse from '../interfaces/edit-response.interface'
import IEditRequest from '../interfaces/edit-request.interface'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).user.access_token

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  }
})
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(deleteToken())
  }
  return result
}
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['TRANSFER', 'LOGIN'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password }) => {
        return {
          url: 'login',
          method: 'POST',
          body: { username: username, password: password }
        }
      },
      invalidatesTags: ['LOGIN']
    }),
    transfer: builder.mutation<ITransferResponse, ITransferRequest>({
      query: ({
        action,
        amount,
        date,
        description,
        photo
      }: ITransferRequest) => {
        const formData = new FormData()
        formData.set('action', action)
        formData.set('amount', amount)
        formData.set('date', date)
        formData.set('description', description)
        formData.set('photo', photo)

        return {
          url: 'transfer',
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (response: ITransferResponse) => {
        return response
      },
      invalidatesTags: ['TRANSFER']
    }),
    getMe: builder.query({
      query: () => 'me',
      transformResponse: (response: IViewResponse) => response.data,
      providesTags: ['TRANSFER', 'LOGIN']
    }),
    edit: builder.mutation<IEditResponse, IEditRequest>({
      query: ({
        id,
        amount,
        date,
        description,
        photo,
        photo_removed
      }: IEditRequest) => {
        const formData = new FormData()

        formData.set('amount', amount)
        formData.set('date', date)
        formData.set('description', description)
        if (photo) {
          formData.set('photo', photo)
        }
        formData.set('photo_removed', photo_removed)

        return {
          url: `/transfer/${id}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (response: IEditResponse) => {
        return response
      },
      invalidatesTags: ['TRANSFER']
    }),
    delete: builder.mutation({
      query: (id: number) => {
        return {
          url: `/transfer/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['TRANSFER']
    })
  })
})

export const {
  useLoginMutation,
  useTransferMutation,
  useGetMeQuery,
  useEditMutation,
  useDeleteMutation
} = api
