import React, { useEffect, useRef, useState } from 'react'
import Layout from '../layouts/Layout'
import FormButton from '../components/FormButton'
import ITransferErrorResponse from '../interfaces/transfer-error-response.interface'
import { useEditMutation } from '../service/Api'
import { useLocation } from 'react-router-dom'
import { changeDateFormatMDY, changeDateFormatYMD } from '../utils/date.utils'

type LocationStateType = {
  data: {
    id: string
    coin: string
    date: string
    description: string
    imageSrc: string | null
  }
}
const Edit = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const location = useLocation()

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const [id, setId] = useState('')

  const [date, setDate] = useState('')
  const [dateInputError, setDateInputError] = useState(false)
  const [dateInputErrorMessage, setDateInputErrorMessage] = useState('')

  const [coins, setCoins] = useState('')
  const [coinsInputError, setCoinsInputError] = useState(false)
  const [coinsInputErrorMessage, setCoinsInputErrorMessage] = useState('')

  const [description, setDescription] = useState('')
  const [descriptionInputError, setDescriptionInputError] = useState(false)
  const [descriptionInputErrorMessage, setDescriptionInputErrorMessage] =
    useState('')

  const [photo, setPhoto] = useState<string | null>('')
  const [photoInput, setPhotoInput] = useState<string | Blob>('')

  const [photoRemoved, setPhotoRemoved] = useState<number>(0)

  const [formError, setFormError] = useState(false)

  const setStates = () => {
    const state = location.state as LocationStateType

    setId(state.data.id)
    setCoins(state.data.coin)
    setDate(changeDateFormatYMD(state.data.date))
    setDescription(state.data.description)
    setPhoto(state.data.imageSrc)
  }

  useEffect(() => {
    if (location.state) {
      setStates()
    }
  }, [])

  const onDateInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDateInputError(false)
    setDateInputErrorMessage('')
    setDate(e.currentTarget.value)
  }

  const onDescriptionInputChange = (
    e: React.FormEvent<HTMLTextAreaElement>
  ) => {
    setDescriptionInputError(false)
    setDescriptionInputErrorMessage('')
    setDescription(e.currentTarget.value)
  }

  const changePhotoButtonClick = () => {
    fileInputRef.current?.click()
  }

  const onCoinsInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCoinsInputError(false)
    setCoinsInputErrorMessage('')
    setCoins(e.currentTarget.value)
  }

  const onPhotoInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target?.files
    if (files && files.length > 0) {
      setPhoto(URL.createObjectURL(files[0]))
      setPhotoInput(files[0])
    }
  }

  const isEditErrorType = (obj: any): obj is ITransferErrorResponse => {
    return (
      'data' in obj &&
      'data' in obj.data &&
      'message' in obj.data &&
      'success' in obj.data
    )
  }

  const [editTransfer] = useEditMutation()

  const onEditButtonClick = async () => {
    setFormError(false)
    setShowSuccessMessage(false)
    setDateInputError(false)
    setDateInputErrorMessage('')
    setCoinsInputError(false)
    setCoinsInputErrorMessage('')
    setDescriptionInputError(false)
    setDescriptionInputErrorMessage('')

    try {
      await editTransfer({
        id: id,
        amount: coins,
        date: changeDateFormatMDY(date),
        description: description,
        photo: photoInput === '' ? null : photoInput,
        photo_removed: photoRemoved
      })
        .unwrap()
        .then(() => {
          setShowSuccessMessage(true)
        })
    } catch (error) {
      if (isEditErrorType(error)) {
        const { data } = error.data
        if (data.amount) {
          setCoinsInputError(true)
          setCoinsInputErrorMessage(data.amount[0])
        }
        if (data.date) {
          setDateInputError(true)
          setDateInputErrorMessage(data.date[0])
        }
        if (data.description) {
          setDescriptionInputError(true)
          setDescriptionInputErrorMessage(data.description[0])
        }
      } else {
        setFormError(true)
      }
    }
  }
  const onDeletePhotoButtonClick = () => {
    setPhoto(null)
    setPhotoInput('')
    setPhotoRemoved(1)
  }

  return (
    <Layout>
      <div className="bg-white w-full grow shadow-top p-3">
        {showSuccessMessage && (
          <React.Fragment>
            <div className="bg-green py-4 m-5 text-sm text-grey-600 border border-grey-600 text-center">
              TRANSFER UPDATED
            </div>
          </React.Fragment>
        )}
        <form className={`${showSuccessMessage ? 'py-3 px-9' : 'p-9'}`}>
          <div className="mb-4 text-sm flex flex-col">
            <label htmlFor="date-input">Date*</label>
            <input
              className={`mt-1 p-2 w-full border ${
                dateInputError ? 'border-red' : 'border-grey-600'
              }`}
              id="date-input"
              type="date"
              value={date}
              onChange={onDateInputChange}
            />
            {dateInputError && dateInputErrorMessage && (
              <div className="text-red mt-1">{dateInputErrorMessage}</div>
            )}
          </div>
          <div className="mb-4 text-sm">
            <label htmlFor="give-input">Coins to Give*</label>
            <input
              className={`mt-1 p-2 w-full border ${
                coinsInputError ? 'border-red' : 'border-grey-600'
              }`}
              id="give-input"
              value={coins}
              onChange={onCoinsInputChange}
            />
            {coinsInputError && coinsInputErrorMessage && (
              <div className="text-red mt-1">{coinsInputErrorMessage}</div>
            )}
          </div>
          <div className="mb-5 text-sm">
            <label htmlFor="description-input">Description*</label>
            <textarea
              className={`mt-1 p-2 w-full border ${
                descriptionInputError ? 'border-red' : 'border-grey-600'
              }`}
              id="description-input"
              value={description}
              onChange={onDescriptionInputChange}
            />
            {descriptionInputError && descriptionInputErrorMessage && (
              <div className="text-red mt-1">
                {descriptionInputErrorMessage}
              </div>
            )}
          </div>
          {photo && <img src={photo} className="max-w-full mb-5" alt="" />}
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={onPhotoInputChange}
          />
          {formError && (
            <div className="text-red text-sm">
              Something wasn&apos;t right. Please try again.
            </div>
          )}
          <div className="flex flex-col gap-3">
            {photo && (
              <FormButton
                text="DELETE PHOTO"
                className="bg-white w-3/5 text-grey-600"
                onClick={() => onDeletePhotoButtonClick()}
              />
            )}
            <FormButton
              text={photo ? 'CHANGE PHOTO' : 'UPLOAD PHOTO'}
              className="bg-white w-3/5 text-grey-600"
              onClick={() => changePhotoButtonClick()}
            />
            <FormButton
              text="UPDATE"
              type="button"
              className="bg-yellow w-3/5 text-grey-600"
              onClick={() => onEditButtonClick()}
            />
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Edit
