import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { api } from '../service/Api'
import storage from 'redux-persist/lib/storage'
import user from './user/user'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer
} from 'redux-persist'
import { setupListeners } from '@reduxjs/toolkit/query'

const reducers = combineReducers({
  user,
  [api.reducerPath]: api.reducer
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(api.middleware)
})

setupListeners(store.dispatch)
const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export { store, persistor }
