import React, { useEffect, useState } from 'react'
import { useGetMeQuery } from '../service/Api'
import ListViewIcon from '../assets/icons/list-view-icon.svg'
import PhotoViewIcon from '../assets/icons/photo-view-icon.svg'
import Layout from '../layouts/Layout'
import TableRow from '../components/TableRow'
import IView from '../interfaces/view.interface'

const View = () => {
  const [activeTab, setActiveTab] = useState('given')
  const [activeListType, setActiveListType] = useState('list-view')
  const [showTotalCoin, setShowTotalCoin] = useState(true)
  const [totalCoin, setTotalCoin] = useState(0)
  const [photos, setPhotos] = useState<string[]>([])

  const { data, isLoading, isSuccess } = useGetMeQuery(undefined, {})

  const clickExpandAll = () => {
    document
      .querySelectorAll('.table-row-item')
      .forEach((item) => item.classList.add('table-row-expanded'))
  }

  const changeActiveTab = (tab: string) => {
    setActiveTab(tab)
    setActiveListType('list-view')
    document
      .querySelectorAll('.table-row-item')
      .forEach((item) => item.classList.remove('table-row-expanded'))
  }

  useEffect(() => {
    const tmpPhotos: string[] = []

    if (!isLoading && isSuccess) {
      setTotalCoin(data.balance)
      if (activeTab === 'given') {
        data.coins_sent.map((item: IView) => {
          if (item.photo_path) {
            tmpPhotos.push(item.photo_path)
          }
        })
      } else {
        data.coins_received.map((item: IView) => {
          if (item.photo_path) {
            tmpPhotos.push(item.photo_path)
          }
        })
      }
      setPhotos(tmpPhotos)
    }
  }, [isLoading, isSuccess, activeTab, activeListType])

  useEffect(() => {
    if (activeListType === 'list-view') {
      setShowTotalCoin(true)
    } else {
      setShowTotalCoin(false)
    }
  }, [activeListType])

  return (
    <Layout
      activeTab="view"
      showTotalCoin={showTotalCoin}
      totalCoin={totalCoin}
    >
      {isLoading && !data ? (
        <div />
      ) : (
        <React.Fragment>
          <div className="w-[80%] mx-auto flex gap-3">
            <button
              className={`${
                activeTab === 'given' ? 'text-grey-600 z-10' : ''
              } w-1/2 text-sm bg-white rounded-t-2xl`}
              onClick={() => changeActiveTab('given')}
            >
              <div className="pt-3 pb-2">
                COINS
                <br />
                GIVEN
              </div>
            </button>
            <button
              className={`${
                activeTab === 'received' ? 'text-grey-600 z-10' : ''
              } w-1/2 text-sm bg-white rounded-t-2xl`}
              onClick={() => changeActiveTab('received')}
            >
              <div className="pt-3 pb-2">
                COINS
                <br />
                RECEIVED
              </div>
            </button>
          </div>
          <div className="bg-white w-full grow shadow-top">
            <div className="py-5 px-8 flex items-center justify-between">
              <button
                className="text-xs text-gray-600 text-center"
                onClick={clickExpandAll}
              >
                EXPAND
                <br />
                ALL PHOTOS
              </button>
              <div className="flex items-center gap-6">
                <button
                  className={`${
                    activeListType === 'list-view' ? '' : 'text-grey-600'
                  } flex flex-col items-center text-[10px]`}
                  onClick={() => setActiveListType('list-view')}
                >
                  <img src={ListViewIcon} alt="" />
                  <div>LIST VIEW</div>
                </button>
                <button
                  className={`${
                    activeListType === 'photos' ? '' : 'text-grey-600'
                  } flex flex-col items-center text-[10px]`}
                  onClick={() => setActiveListType('photos')}
                >
                  <img src={PhotoViewIcon} alt="" />
                  <div>PHOTOS</div>
                </button>
              </div>
            </div>
            {activeTab === 'given' ? (
              <React.Fragment>
                {activeListType === 'list-view' && (
                  <div className="bg-white pb-11">
                    {data && data.coins_sent && data.coins_sent.length > 0 ? (
                      <React.Fragment>
                        {data.coins_sent.map((item: IView, i: number) => {
                          return (
                            <TableRow
                              key={`item-${i.toString()}`}
                              id={item.id}
                              index={i}
                              coin={item.amount}
                              date={item.date}
                              description={item.description}
                              imageSrc={item.photo_path}
                            />
                          )
                        })}
                      </React.Fragment>
                    ) : (
                      <div />
                    )}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeListType === 'list-view' && (
                  <div className="bg-white pb-11">
                    {data &&
                    data.coins_received &&
                    data.coins_received.length > 0 ? (
                      <React.Fragment>
                        {data.coins_received.map((item: IView, i: number) => {
                          return (
                            <TableRow
                              key={`item-${i.toString()}`}
                              id={item.id}
                              index={i}
                              coin={item.amount}
                              date={item.date}
                              description={item.description}
                              imageSrc={item.photo_path}
                            />
                          )
                        })}
                      </React.Fragment>
                    ) : (
                      <div />
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
            {activeListType === 'photos' && (
              <div className="flex flex-wrap pb-11 items-end">
                {photos.length > 0 &&
                  photos.map((photo, p) => {
                    return (
                      <img
                        key={`photo-${p.toString()}`}
                        src={photo}
                        className="w-1/3 p-3"
                        alt=""
                      />
                    )
                  })}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default View
