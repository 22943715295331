import React from 'react'

type Props = {
  className?: string
  text: string
  onClick?: () => void
  id?: string
  type?: 'button' | 'submit' | 'reset' | undefined
}
const FormButton = (props: Props) => {
  return (
    <button
      className={`${
        props.className ? `${props.className}` : ''
      } rounded-30 border border-grey-600 py-2.5 px-4 text-sm`}
      onClick={props.onClick}
      type={props.type ? props.type : 'button'}
      id={props.id}
    >
      {props.text}
    </button>
  )
}

export default FormButton
