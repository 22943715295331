import React, { useEffect, useRef, useState } from 'react'
import '../styles/table-row.scss'
import { Link } from 'react-router-dom'
import { IModalRefObject } from '../interfaces/modal.interface'
import DeleteModal from './DeleteModal'

type Props = {
  id: number
  index: number
  coin: number
  date: string
  description: string
  imageSrc: string | null
}
const TableRow = (props: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const rowContainerRef = useRef<HTMLDivElement>(null)
  const rowButtonsRef = useRef<HTMLDivElement>(null)
  const deleteModalRef = useRef<IModalRefObject>(null)

  const expand = () => {
    const className = wrapperRef.current?.classList.value
    if (className?.includes('table-row-expanded')) {
      wrapperRef.current?.classList.remove('table-row-expanded')
    } else {
      wrapperRef.current?.classList.add('table-row-expanded')
    }
  }

  const closeExpand = () => {
    if (wrapperRef.current) {
      const className = wrapperRef.current.classList.value
      if (className.includes('table-row-expanded')) {
        wrapperRef.current?.classList.remove('table-row-expanded')
      }
    }
  }

  const [xDown, setXDown] = useState(null)
  const [yDown, setYDown] = useState(null)

  const getTouches = (evt: any) => {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ) // jQuery
  }

  const handleTouchStart = (evt: React.TouchEvent<HTMLDivElement>) => {
    const firstTouch = getTouches(evt)[0]
    setXDown(firstTouch.clientX)
    setYDown(firstTouch.clientY)
  }

  const handleTouchMove = (evt: React.TouchEvent<HTMLDivElement>) => {
    if (!xDown || !yDown) {
      return
    }
    const xUp = evt.touches[0].clientX
    const yUp = evt.touches[0].clientY

    const xDiff = xDown - xUp
    const yDiff = yDown - yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        closeExpand()
        document.querySelectorAll('.show-buttons').forEach((element) => {
          element.classList.remove('show-buttons')
        })
        wrapperRef.current?.classList.add('show-buttons')
      } else {
        wrapperRef.current?.classList.remove('show-buttons')
      }
    }
    setXDown(null)
    setYDown(null)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement

      if (wrapperRef.current && !wrapperRef.current.contains(target)) {
        wrapperRef.current?.classList.remove('show-buttons')
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const onDeleteBtnClick = () => {
    wrapperRef.current?.classList.remove('show-buttons')
    deleteModalRef.current?.open()
  }

  return (
    <div
      className={`${
        props.index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
      } table-row-item text-sm relative`}
      ref={wrapperRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="flex flex-col row-container" ref={rowContainerRef}>
        <div className="flex items-start table-row-item p-3">
          <div className="w-[50%] flex items-start text-center">
            <div className="w-1/4">
              {props.imageSrc && (
                <img
                  className="cursor-pointer table-row-img w-8 h-8"
                  onClick={() => expand()}
                  alt=""
                />
              )}
            </div>
            <div className="w-1/4">{props.coin}</div>
            <div className="w-1/2">{props.date}</div>
          </div>
          <div className="w-[50%] ml-4 break-words">{props.description}</div>
        </div>
        <div className="description-img mt-1 flex justify-start w-[50%] ml-auto p-3 pt-0">
          {props.imageSrc && <img src={props.imageSrc} alt="" />}
        </div>
      </div>
      <div
        className="flex items-end absolute right-0 top-0 z-10 h-full row-buttons text-white"
        ref={rowButtonsRef}
      >
        <Link
          className="h-full w-10"
          to={'/edit'}
          state={{
            data: {
              id: props.id,
              coin: props.coin,
              date: props.date,
              description: props.description,
              imageSrc: props.imageSrc
            }
          }}
        >
          <button className="bg-grey-100 p-3 h-full w-10 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
          </button>
        </Link>
        <button
          className="bg-red-100 p-3 h-full w-10 flex items-center justify-center"
          onClick={() => onDeleteBtnClick()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <DeleteModal ref={deleteModalRef} id={props.id} />
    </div>
  )
}

export default TableRow
