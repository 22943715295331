import React, { useRef, useState } from 'react'
import Layout from '../layouts/Layout'
import FormButton from '../components/FormButton'
import { useTransferMutation } from '../service/Api'
import ITransferErrorResponse from '../interfaces/transfer-error-response.interface'

const Give = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const [date, setDate] = useState('')
  const [dateInputError, setDateInputError] = useState(false)
  const [dateInputErrorMessage, setDateInputErrorMessage] = useState('')

  const [coinsToGive, setCoinsToGive] = useState('')
  const [coinsToGiveInputError, setCoinsToGiveInputError] = useState(false)
  const [coinsToGiveInputErrorMessage, setCoinsToGiveInputErrorMessage] =
    useState('')

  const [description, setDescription] = useState('')
  const [descriptionInputError, setDescriptionInputError] = useState(false)
  const [descriptionInputErrorMessage, setDescriptionInputErrorMessage] =
    useState('')

  const [photo, setPhoto] = useState('')
  const [photoInput, setPhotoInput] = useState<string | Blob>('')

  const [formError, setFormError] = useState(false)

  const [transfer] = useTransferMutation()

  const onDateInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDateInputError(false)
    setDateInputErrorMessage('')
    setDate(e.currentTarget.value)
  }
  const onCoinsToGiveInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCoinsToGiveInputError(false)
    setCoinsToGiveInputErrorMessage('')
    setCoinsToGive(e.currentTarget.value)
  }
  const onDescriptionInputChange = (
    e: React.FormEvent<HTMLTextAreaElement>
  ) => {
    setDescriptionInputError(false)
    setDescriptionInputErrorMessage('')
    setDescription(e.currentTarget.value)
  }
  const onPhotoInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target?.files
    if (files && files.length > 0) {
      setPhoto(URL.createObjectURL(files[0]))
      setPhotoInput(files[0])
    }
  }

  const onSendButtonClick = async () => {
    setFormError(false)
    setShowSuccessMessage(false)
    setDateInputError(false)
    setDateInputErrorMessage('')
    setCoinsToGiveInputError(false)
    setCoinsToGiveInputErrorMessage('')
    setDescriptionInputError(false)
    setDescriptionInputErrorMessage('')

    if (date === '') {
      setDateInputError(true)
      setDateInputErrorMessage('Date field can not be blank')
    }
    if (coinsToGive === '') {
      setCoinsToGiveInputError(true)
      setCoinsToGiveInputErrorMessage('Coins to give field can not be blank')
    }
    if (description === '') {
      setDescriptionInputError(true)
      setDescriptionInputErrorMessage('Description field can not be blank')
    }

    if (date !== '' && coinsToGive !== '' && description !== '') {
      try {
        await transfer({
          action: 'give',
          amount: coinsToGive,
          date: date,
          description: description,
          photo: photoInput
        })
          .unwrap()
          .then(() => {
            clearForm()
            setShowSuccessMessage(true)
          })
      } catch (error) {
        if (isTransferErrorType(error)) {
          const { data } = error.data
          if (data.amount) {
            setCoinsToGiveInputError(true)
            setCoinsToGiveInputErrorMessage(data.amount[0])
          }
          if (data.date) {
            setDateInputError(true)
            setDateInputErrorMessage(data.date[0])
          }
          if (data.description) {
            setDescriptionInputError(true)
            setDescriptionInputErrorMessage(data.description[0])
          }
        } else {
          setFormError(true)
        }
      }
    }
  }
  const isTransferErrorType = (obj: any): obj is ITransferErrorResponse => {
    return (
      'data' in obj &&
      'data' in obj.data &&
      'message' in obj.data &&
      'success' in obj.data
    )
  }

  const uploadPhotoButtonClick = () => {
    fileInputRef.current?.click()
  }
  const clearForm = () => {
    setDate('')
    setCoinsToGive('')
    setDescription('')
    setPhoto('')
    setPhotoInput('')
  }

  return (
    <Layout activeTab="give" showTotalCoin={false}>
      <div className="bg-white w-full grow shadow-top">
        {showSuccessMessage && (
          <React.Fragment>
            <div className="bg-green py-4 m-5 text-sm text-grey-600 border border-grey-600 text-center">
              THANK YOU, COIN(S) GIVEN
            </div>
            <div className="px-9 text-sm text-grey-600">GIVE ANOTHER:</div>
          </React.Fragment>
        )}
        <form className={`${showSuccessMessage ? 'py-3 px-9' : 'p-9'}`}>
          <div className="mb-4 text-sm flex flex-col">
            <label htmlFor="date-input">Date*</label>
            <input
              className={`mt-1 p-2 w-full border ${
                dateInputError ? 'border-red' : 'border-grey-600'
              }`}
              id="date-input"
              type="date"
              value={date}
              onChange={onDateInputChange}
            />
            {dateInputError && dateInputErrorMessage && (
              <div className="text-red mt-1">{dateInputErrorMessage}</div>
            )}
          </div>
          <div className="mb-4 text-sm">
            <label htmlFor="give-input">Coins to Give*</label>
            <input
              className={`mt-1 p-2 w-full border ${
                coinsToGiveInputError ? 'border-red' : 'border-grey-600'
              }`}
              id="give-input"
              value={coinsToGive}
              onChange={onCoinsToGiveInputChange}
            />
            {coinsToGiveInputError && coinsToGiveInputErrorMessage && (
              <div className="text-red mt-1">
                {coinsToGiveInputErrorMessage}
              </div>
            )}
          </div>
          <div className="mb-5 text-sm">
            <label htmlFor="description-input">Description*</label>
            <textarea
              className={`mt-1 p-2 w-full border ${
                descriptionInputError ? 'border-red' : 'border-grey-600'
              }`}
              id="description-input"
              value={description}
              onChange={onDescriptionInputChange}
            />
            {descriptionInputError && descriptionInputErrorMessage && (
              <div className="text-red mt-1">
                {descriptionInputErrorMessage}
              </div>
            )}
          </div>
          {photo && <img src={photo} className="max-w-full mb-5" alt="" />}
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={onPhotoInputChange}
          />
          {formError && (
            <div className="text-red text-sm">
              Something wasn&apos;t right. Please try again.
            </div>
          )}
          <div className="flex flex-col gap-3">
            <FormButton
              text="UPLOAD PHOTO"
              onClick={() => uploadPhotoButtonClick()}
              className="bg-white w-3/5 text-grey-600"
            />
            <FormButton
              text="SEND"
              className="bg-yellow w-3/5 text-grey-600"
              onClick={() => onSendButtonClick()}
            />
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Give
