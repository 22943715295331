import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import FormButton from './FormButton'
import { useDeleteMutation } from '../service/Api'

type Props = {
  id: number
}
const DeleteModal = forwardRef((props: Props, ref) => {
  const [showModal, setShowModal] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true)
    },
    close: () => {
      setShowModal(false)
    }
  }))
  const [deleteTransfer] = useDeleteMutation()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement

      if (wrapperRef.current && !wrapperRef.current.contains(target)) {
        setShowModal(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const onDeleteButtonClick = async () => {
    await deleteTransfer(props.id)
    setShowModal(false)
  }

  return (
    <div
      className={`${
        showModal ? 'block' : 'hidden'
      } flex justify-center lg:items-center items-center fixed h-full top-0 left-0 right-0 bg-black/40 overflow-x-hidden z-50`}
    >
      <div
        className="bg-white rounded w-1/2 w-[70%] px-5 py-8"
        ref={wrapperRef}
      >
        <div className="text-xl text-red-100 text-center mb-5">Delete</div>
        <div className="text-gray-600 text-center mb-5">
          Do you really want to delete this item? This process cannot be undone.
        </div>
        <div className="flex items-center justify-center gap-3">
          <FormButton
            text="Cancel"
            className="bg-white text-grey-600"
            onClick={() => setShowModal(false)}
          />
          <FormButton
            text="Delete"
            className="bg-red-100 text-white"
            onClick={() => onDeleteButtonClick()}
          />
        </div>
      </div>
    </div>
  )
})

DeleteModal.displayName = 'DeleteModal'
export default DeleteModal
