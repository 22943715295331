import React from 'react'
import Logo from '../assets/logo.svg'
import ViewCoinIcon from '../assets/icons/view-coin.svg'
import TakeCoinIcon from '../assets/icons/take-coin.svg'
import GiveCoinIcon from '../assets/icons/give-coin.svg'
import { Link, useNavigate } from 'react-router-dom'

type Props = {
  children: React.ReactNode
  activeTab?: string
  showTotalCoin?: boolean
  totalCoin?: number
}
const Layout = (props: Props) => {
  const navigate = useNavigate()
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-lightBlue flex flex-col">
      <div className="sm:w-[24rem] w-[90%] mx-auto flex flex-col pt-5 pb-24 grow overflow-x-hidden">
        <div className="w-[70%] mx-auto mb-5">
          <Link to="/view">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="flex flex-col grow">{props.children}</div>
      </div>
      <div className="fixed bottom-0 w-full z-40">
        {props.showTotalCoin && (
          <div className="sm:w-[22rem] w-full mx-auto bg-red rounded-t-xl text-white py-2 text-center border border-b-0 border-grey-600">
            BALANCE: <span className="font-medium">{props.totalCoin}</span>
          </div>
        )}
        <div className="sm:w-[24rem] w-full mx-auto py-3 bg-white shadow-top">
          <div className="w-[80%] mx-auto flex items-center justify-between">
            <button
              className={`${
                props.activeTab === 'view' ? '' : 'text-gray-600'
              } text-xs flex flex-col items-center`}
              onClick={() => navigate('/view')}
            >
              <img
                src={ViewCoinIcon}
                className={`${
                  props.activeTab === 'view' ? 'shadow-right' : ''
                } rounded-full`}
                alt=""
              />
              <div className="mt-1">VIEW</div>
            </button>
            <button
              className={`${
                props.activeTab === 'give' ? '' : 'text-gray-600'
              } text-xs flex flex-col items-center`}
              onClick={() => navigate('/give')}
            >
              <img
                src={GiveCoinIcon}
                className={`${
                  props.activeTab === 'give' ? 'shadow-right' : ''
                } rounded-full`}
                alt=""
              />
              <div className="mt-1">GIVE</div>
            </button>
            <button
              className={`${
                props.activeTab === 'take' ? '' : 'text-gray-600'
              } text-xs flex flex-col items-center`}
              onClick={() => navigate('/take')}
            >
              <img
                src={TakeCoinIcon}
                className={`${
                  props.activeTab === 'take' ? 'shadow-right' : ''
                } rounded-full`}
                alt=""
              />
              <div className="mt-1">TAKE</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
