export const changeDateFormatMDY = (date: string) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = newDate.getMonth() + 1
  const day = newDate.getDate()
  return `${month.toString().padStart(2, '0')}/${day
    .toString()
    .padStart(2, '0')}/${year}`
}

export const changeDateFormatYMD = (date: string) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = newDate.getMonth() + 1
  const day = newDate.getDate()
  return `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`
}
